<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          ฝากเครดิต
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5 class="font-weight-bolder m-0">
          เลือกธนาคารที่ต้องการโอนเงิน
        </h5>
      </div>
      <div class="text-center">
        <small
          class="text-danger"
        ><u>"กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น"</u></small><br>
        <small
          class="text-yellow"
        >*ระบบจะเติมเครดิตเกมอัตโนมัติ ภายในเวลา 1-2 นาที</small>
      </div>

      <!-- ****** -->
      <div
        v-if="agent.checkbank1 === 1"
        class="row card-list mt-1"
        @click="scb()"
      >
        <div class="col-3 text-left p-0">
          <div
            style="
              background-color: #4e2e7f;
              border-radius: 6px;
              width: 50px;
              height: 50px;
              padding: 5px;
            "
          >
            <img
              src="@/assets/Bank/SCB.png"
              alt="bank"
              height="40px"
            >
          </div>
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            ธนาคาร ไทยพาณิชย์ จำกัด (มหาชน) บัญชี 1
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>

      <div
        v-if="agent.dip_bank2_status === 1"
        class="row card-list mt-1"
        @click="kbank()"
      >
        <div class="col-3 text-left p-0">
          <div
            style="
              background-color: #0f8f46;
              border-radius: 6px;
              width: 50px;
              height: 50px;
              padding: 5px;
            "
          >
            <img
              src="@/assets/Bank/KBANK.png"
              alt="bank"
              height="40px"
            >
          </div>
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            ธนาคาร กสิกรไทย จำกัด (มหาชน)
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>

      <div
        v-if="agent.dip_bank4_status === 1"
        class="row card-list mt-1"
        @click="kbank2()"
      >
        <div class="col-3 text-left p-0">
          <div
            style="
              background-color: #0f8f46;
              border-radius: 6px;
              width: 50px;
              height: 50px;
              padding: 5px;
            "
          >
            <img
              src="@/assets/Bank/KBANK.png"
              alt="bank"
              height="40px"
            >
          </div>
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            ธนาคาร กสิกรไทย จำกัด (มหาชน) บัญชี 2
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>

      <div
        v-if="agent.checkbank3 === 1"
        class="row card-list mt-1"
        @click="scb2()"
      >
        <div class="col-3 text-left p-0">
          <div
            style="
              background-color: #4e2e7f;
              border-radius: 6px;
              width: 50px;
              height: 50px;
              padding: 5px;
            "
          >
            <img
              src="@/assets/Bank/SCB.png"
              alt="bank"
              height="40px"
            >
          </div>
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            ธนาคาร ไทยพาณิชย์ จำกัด (มหาชน) บัญชี 2
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>

      <div
        v-if="agent.checktruewallet === 1"
        class="row card-list mt-1"
        @click="walet()"
      >
        <div class="col-3 text-left p-0">
          <img
            src="/bankIcon/true.svg"
            alt="bank"
            height="50px"
          >
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            ทรูมันนี่ วอลเล็ท
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>

      <div
        v-if="agent.check_dep_crypto === 1"
        class="row card-list mt-1"
        @click="crypto()"
      >
        <div class="col-3 text-left p-0">
          <img
            src="/bankIcon/usdt.png"
            alt="USDT"
            height="50px"
          >
        </div>
        <div class="col-6 text-center p-0">
          <h5 class="mt-1">
            เหรียญคริปโต (USDT)
          </h5>
        </div>
        <div class="col-3 text-right p-0">
          <i
            class="fas fa-fighter-jet"
            style="margin-top: 18.5px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agent: {},
    }
  },
  mounted() {
    this.GetAgentData()
    // if (this.userData.bank === '004') {
    //   this.$swal({
    //     title: 'แจ้งเตือน',
    //     text: 'ลูกค้าที่ใช้ธนาคารกสิกรแนะนำให้ฝากเงินเข้าบัญชีไทยพาณิชย์ เพื่อรับเครดิตเกมได้เร็วขึ้นค่ะ',
    //     icon: 'warning',
    //     confirmButtonText: 'ตกลง',
    //   })
    // }
  },
  methods: {
    scb() {
      if (this.agent.status_dip === '1') {
        this.$router.push({ name: 'scb' })
      } else {
        this.$swal({
          title: 'แจ้งเตือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    scb2() {
      if (this.agent.status_dip === '1') {
        this.$router.push({ name: 'scb2' })
      } else {
        this.$swal({
          title: 'แจ้งเตือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    kbank() {
      if (this.agent.dip_bank2_botstatus === 1) {
        this.$router.push({ name: 'kbank' })
      } else {
        this.$swal({
          title: 'แจ้งเตือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    kbank2() {
      if (this.agent.dip_bank4_botstatus === 1) {
        this.$router.push({ name: 'kbank2' })
      } else {
        this.$swal({
          title: 'แจ้งเตือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    walet() {
      if (this.agent.status_dip === '1') {
        this.$router.push({ name: 'wallet' })
      } else {
        this.$swal({
          title: 'แจ้งเดือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    crypto() {
      if (this.agent.status_dip === '1') {
        this.$router.push({ name: 'crypto' })
      } else {
        this.$swal({
          title: 'แจ้งเดือน',
          text: 'ขณะนี้เว็บได้ทำการปิดระบบฝากเงินชั่วคราว',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
}
.card-list {
  margin: 0 5px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
</style>
